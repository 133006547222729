import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ContactUs from '../components/ContactUs';

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Image className="mt-16" fluid={data.Hero.childImageSharp.fluid} />
      <ContactUs data={data} />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object,
};

export default ContactPage;

export const query = graphql`
  query ContactPageQuery {
    Hero: file(relativePath: { eq: "contact-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mcgLogo: file(relativePath: { eq: "mcg-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 173, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        address
        facebook
        googleMaps
        phone
        phoneFormatted
        title
      }
    }
  }
`;
